





































































































































































































































import { Component, Vue } from "vue-property-decorator";
import PageTitle from "@/components/PageTitle.vue";
import OutlinedTextInput from "@/components/OutlinedTextInput.vue";
import OutlinedTextArea from "@/components/OutlinedTextArea.vue";
import OutlinedSelect from "@/components/OutlinedSelect.vue";
import DatePicker from "vue2-datepicker";
import "@/catering/assets/datepicker.scss";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import axios from "axios";
import Circle4 from "@/assets/Circle4Spinner.vue";

@Component({
	components: {
		PageTitle,
		OutlinedTextInput,
		DatePicker,
		OutlinedTextArea,
		OutlinedSelect,
		LottieAnimation,
		Circle4,
	},
})
export default class Catering extends Vue {
	name = "";
	email = "";
	emailConfirmation = "";
	phoneNumber = "";
	nameErrorMessage = "";
	emailErrorMessage = "";
	eventDate = undefined;
	numberOfKnots = null;
	knotSize = null;
	notes = null;
	isLoading = false;
	showingSuccessModal = false;
	showingErrorModal = false;
	whois = [
		"Wedding Reception",
		"Morning Meeting",
		"Birthday Party",
		"Family Reunion",
		"Wedding Dinner",
		"Fall Festival",
		"Office Party",
		"Book Club",
		"Holiday Gift",
		"Pool Party",
		"Winter Carnival",
		"Team Mom",
		"Block Party",
	];

	disabledBeforeToday(date: Date): boolean {
		const today = new Date();
		today.setHours(0, 0, 0, 0);

		return date < today;
	}

	mounted() {
		window.setInterval(() => {
			this.pollPerson();
		}, 2000);
	}

	pollPerson() {
		const first = this.whois.shift() as string;
		this.whois = this.whois.concat(first);
	}

	scrollToElement() {
		const el = this.$refs.scrollToMe as Element;

		if (el) {
			// Use el.scrollIntoView() to instantly scroll to the element
			el.scrollIntoView({ behavior: "smooth" });
		}
	}

	submitForm() {
		if (this.name === "") {
			//Show Name is Required
			this.nameErrorMessage = "Please add your name 🙂";
			return;
		} else {
			this.nameErrorMessage = "";
		}

		if (this.email === "") {
			//Show Email is Required
			this.emailErrorMessage = "Please add your email 🙂";
			return;
		} else {
			this.emailErrorMessage = "";
		}

		if (!this.validEmail(this.email)) {
			this.emailErrorMessage = "Please add a valid email 🙂";
			return;
		} else {
			this.emailErrorMessage = "";
		}

		if (this.emailConfirmation !== "") {
			return;
		}

		this.isLoading = true;
		axios.defaults.headers.post["Content-Type"] = "application/json";
		axios
			.post(
				"https://formsubmit.co/ajax/a60339c83081f09ad8beec5d24482e73",
				{
					Name: this.name,
					Email: this.email,
					Phone_Number: this.phoneNumber,
					Event_Date: this.getFormattedDate(this.eventDate),
					Number_Of_Knots: this.numberOfKnots,
					Knot_Size: this.knotSize,
					Notes: this.notes,
				}
			)
			.then((response) => {
				this.resetFormInputs();
				this.showingSuccessModal = true;
			})
			.catch((error) => {
				this.isLoading = false;
				this.showingErrorModal = true;
			});
	}

	resetFormInputs() {
		this.isLoading = false;
		this.name = "";
		this.email = "";
		this.phoneNumber = "";
		this.eventDate = undefined;
		this.numberOfKnots = null;
		this.knotSize = null;
		this.notes = null;
		this.emailConfirmation = "";
	}

	validEmail(email: string): boolean {
		const re =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	getFormattedDate(date?: Date): string {
		if (date == undefined) {
			return "";
		}
		let year = date.getFullYear();
		let month = (1 + date.getMonth()).toString().padStart(2, "0");
		let day = date.getDate().toString().padStart(2, "0");

		return month + "/" + day + "/" + year;
	}
}
