














import { Component, Prop, Vue, VModel } from "vue-property-decorator";
@Component
export default class OutlinedTextInput extends Vue {
	@Prop() private borderColor?: string;
	@Prop() private textColor?: string;
	@Prop() private labelColor?: string;
	@Prop() private label?: string;
	@Prop() private borderRadius?: string;
	@Prop() private inputType?: string;
	@Prop() private min?: string;
	@VModel({ type: String }) model!: string;

	get cssProps() {
		return {
			"--borderColor": this.borderColor ? this.borderColor : "#6200ee",
			"--textColor": this.textColor
				? this.textColor
				: this.borderColor
				? this.borderColor
				: "#6200ee",
			"--borderRadius": this.borderRadius ? this.borderRadius : "5px",
			"--labelColor": this.labelColor ? this.labelColor : "#6200ee",
		};
	}
}
