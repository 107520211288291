













import { Component, Vue } from "vue-property-decorator";
import router from "@/app/router";

@Component
export default class NotFound404 extends Vue {
	returnHome(): void {
		window.scrollTo(0, 0);
		router.push("/");
	}
}
