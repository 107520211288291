













































import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class QA extends Vue {
	@Prop() private question?: string;
	@Prop() private answer?: string;
	@Prop() private accordion?: number;
	@Prop() private id?: number;
}
