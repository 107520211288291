interface IKnotFlavor {
	name: string;
	image: string;
	description: string;
}

export default class KnotFlavor implements IKnotFlavor {
	name: string;
	image: string;
	description: string;

	constructor(name: string, description: string, image: string) {
		this.name = name;
		this.description = description;
		this.image = image;
	}
}
