export function sameDay(firstDate, secondDate) {
	return (
		firstDate.getDate() == secondDate.getDate() &&
		firstDate.getMonth() == secondDate.getMonth() &&
		firstDate.getFullYear() == secondDate.getFullYear()
	);
}

export function getSunday(d) {
	d = new Date(d);
	var day = d.getDay(),
		diff = d.getDate() - day;
	return new Date(d.setDate(diff));
}

export function addDays(date, amount) {
	var newDate = new Date(date);
	newDate.setDate(date.getDate() + amount);
	return newDate;
}

export function isOdd(num) {
	return num % 2;
}
