














import { Component, Vue } from "vue-property-decorator";
import QA from "@/faq/ui/QA.vue";
import PageTitle from "@/components/PageTitle.vue";

@Component({
	components: {
		QA,
		PageTitle,
	},
})
export default class FAQ extends Vue {
	questions = [
		{
			question: "How do I heat up my Knots?",
			answer: "Great question! One so important it gets its own page. Look for the link in the header or footer for our <b>Warming Instructions</b> page.",
		},
		{
			question: "What are my knot ordering options?",
			answer: "Currently Knots are only available for purchase in-person at an event, or through a catering pre-order.  We’re searching for the perfect location for our first permanent bakery, and are actively working on our online ordering system.  Thank you for your patience and loyalty as we continue to grow!",
		},
		{
			question:
				"What if I want to pre-order, but need less than 30 knots?",
			answer: "We LOVE our Knots Family. Shoot us an email at <a class='email white' href='mailto:contact@knotsbakery.com'>contact@knotsbakery.com</a>.  We’ll do our absolute best to accommodate your need.",
		},
		{
			question: "How much do Knots cost?",
			answer: "Box of 4: $14.99",
		},
		{
			question: "Do you have Gluten Free/Vegan/Keto options available?",
			answer: "We do not currently have keto, vegan, or gluten-free options available, but are always exploring new flavors.",
		},
		{
			question: "Refunds, Concerns and Feedback, where should I go?",
			answer: "We LOVE our Knots Family. For any type of refunds, concerns or other feedback - please don’t hesitate to contact our support team at <a class='email white' href='mailto:contact@knotsbakery.com'>contact@knotsbakery.com</a>.",
		},
		{
			question:
				"Who can I talk to about Collaboration Opportunities, Donations and Partnerships?",
			answer: "For collaboration opportunities, local donations and partnerships, please contact us at <a class='email white' href='mailto:contact@knotsbakery.com'>contact@knotsbakery.com</a>. We LOVE our Knots family and can’t wait to hear from you!",
		},
	];
}
