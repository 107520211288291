
















































import { Component, Vue } from "vue-property-decorator";
import PageTitle from "@/components/PageTitle.vue";

@Component({
	components: {
		PageTitle,
	},
})
export default class HeatingInstructions extends Vue {}
