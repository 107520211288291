import googleClient from "@/api/googleClient.ts";
import EventsListResponse from "@/model/EventsListResponse";

const url = `${process.env.VUE_APP_GOOGLE_CALENDAR_BASE_URL}events`;
// const res = await client.request({url});

const listEvents = (
	maxResults?: number,
	pageToken?: string,
	timeMax?: string,
	timeMin?: string,
	timeZone?: string
) => googleClient.request<EventsListResponse>({ url });

export { listEvents };
