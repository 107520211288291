















































































































import { Component, Vue } from "vue-property-decorator";
import router from "@/app/router";

@Component
export default class Footer extends Vue {
	scrollToTop() {
		window.scrollTo(0, 0);
	}

	logoClicked() {
		this.scrollToTop();
		router.push("/");
	}
}
