





































import { Component, Vue } from "vue-property-decorator";
import Schedule from "@/schedule/Schedule.vue";
import FlavorDetails from "@/components/FlavorDetails.vue";
import KnotFlavor from "@/model/KnotFlavor";
import { isOdd } from "@/helpers/helpers";

@Component({
	components: {
		FlavorDetails,
		Schedule,
	},
	methods: {
		isOdd,
	},
})
export default class Home extends Vue {
	flavors = [
		new KnotFlavor(
			"Cinna Love",
			"Our most popular flavor! Packed with cinnamon brown sugar filling and glazed with silky maple frosting, these knots are the perfect mashup between cinnamon rolls and maple bars.",
			require(`@/assets/cinnamon-min.jpg`)
		),
		new KnotFlavor(
			"Classic Orange",
			"A holiday favorite, that's classic anytime of the year. Sweet, tangy orange juice mingled with grated orange zest leave these knots bursting with flavor and covered in a delicious orange glaze to die for.",
			require(`@/assets/orange-min.jpg`)
		),
		new KnotFlavor(
			"Le-Blueberry",
			"Bursting with blueberries And covered in a sweet, tangy lemon glaze, these knots are the perfect treat for any time of the day.",
			require(`@/assets/lemon_blueberry-min.jpg`)
		),
		new KnotFlavor(
			"Cinnamon Cream Cheese",
			"Packed with cinnamon brown sugar filling and glzed with our homemade cream cheese frosting, these knots take a classic cinnamon roll and make every bite balanced and full of love.",
			require(`@/assets/cinnamon_cc-min.jpg`)
		),
	];
}
