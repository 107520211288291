













import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class PageTitle extends Vue {
	@Prop() private title!: string;
	@Prop() private centered!: boolean;
}
