































import { Component, Prop, Vue } from "vue-property-decorator";
import PageTitle from "@/components/PageTitle.vue";

@Component({
	components: {
		PageTitle,
	},
})
export default class PageHeaderImageTitleDescription extends Vue {
	@Prop() private title!: string;
	@Prop() private centered!: boolean;
	@Prop() private image!: string;
	@Prop() private description!: string;
	@Prop() private imageCornerRadius!: string;

	get cssProps() {
		return {
			"--imageCornerRadius": this.imageCornerRadius
				? this.imageCornerRadius
				: "0px",
		};
	}
}
