












import { Component, Vue } from "vue-property-decorator";
import PageHeaderImageTitleDescription from "@/components/PageHeaderImageTitleDescription.vue";

@Component({
	components: {
		PageHeaderImageTitleDescription,
	},
})
export default class OurStory extends Vue {}
