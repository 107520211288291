






















import { Inject, Component, Prop, Vue, Watch } from "vue-property-decorator";
import CalendarEvent from "@/model/CalendarEvent";
import CalendarDay from "@/model/CalendarDay";

@Component
export default class ScheduleDayOverview extends Vue {
	@Prop() day!: CalendarDay;
	@Prop() selected!: boolean;
	@Inject() selectedEvent?: CalendarEvent;

	weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
	month = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];
}
