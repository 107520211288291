import Vue from "vue";
import Vuex from "vuex";
import createLogger from "vuex/dist/logger";

// import the auto exporter
import modules from "@/store/modules";

const debug = process.env.NODE_ENV !== "production";

Vue.use(Vuex);

export default new Vuex.Store({
	modules, // all the modules automatically imported :)
	actions: {
		reset({ commit }) {
			// resets state of all the modules
			Object.keys(modules).forEach((moduleName) => {
				commit(`${moduleName}/RESET`);
			});
		},
	},
	strict: debug,
	plugins: debug ? [createLogger()] : [], // set logger only for development
});

//https://haxzie.com/architecting-vuex-store-large-scale-vue-application
