



























































































import { Watch, Provide, Component, Vue } from "vue-property-decorator";
import ScheduleDayOverview from "@/schedule/ScheduleDayOverview.vue";
import CalendarEvent from "@/model/CalendarEvent";
import { sameDay, getSunday, addDays } from "@/helpers/helpers";
import CalendarDay from "@/model/CalendarDay";
import { mapActions, mapGetters } from "vuex";

@Component({
	components: {
		ScheduleDayOverview,
	},
	methods: {
		getSunday,
		addDays,
		sameDay,
		...mapActions("Events", ["fetchEvents"]),
	},
	computed: {
		...mapGetters("Events", ["getEvents"]),
	},
})
export default class Schedule extends Vue {
	@Provide() selectedEvent?: CalendarEvent = undefined;
	monthLeft = "";
	monthRight = "";
	monthLeftColumns = 12;
	monthRightColumns = 0;
	loading = false;
	sunday = getSunday(Date());
	monday = addDays(this.sunday, 1);
	tuesday = addDays(this.sunday, 2);
	wednesday = addDays(this.sunday, 3);
	thursday = addDays(this.sunday, 4);
	friday = addDays(this.sunday, 5);
	saturday = addDays(this.sunday, 6);

	sunDay: CalendarDay = { date: this.sunday, events: [] };
	monDay: CalendarDay = { date: this.monday, events: [] };
	tueDay: CalendarDay = { date: this.tuesday, events: [] };
	wedDay: CalendarDay = { date: this.wednesday, events: [] };
	thuDay: CalendarDay = { date: this.thursday, events: [] };
	friDay: CalendarDay = { date: this.friday, events: [] };
	satDay: CalendarDay = { date: this.saturday, events: [] };
	month = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];
	page = 0;
	@Provide() selectedDay: CalendarDay = this.getSelectedDay();

	nextPage(): void {
		this.page += 1;
	}

	fetchEvents!: () => Promise<void>;

	previousPage(): void {
		this.page -= 1;
	}

	getSelectedDay(): CalendarDay {
		this.sunday = getSunday(Date());
		this.monday = addDays(this.sunday, 1);
		this.tuesday = addDays(this.sunday, 2);
		this.wednesday = addDays(this.sunday, 3);
		this.thursday = addDays(this.sunday, 4);
		this.friday = addDays(this.sunday, 5);
		this.saturday = addDays(this.sunday, 6);

		this.sunDay = { date: this.sunday, events: [] };
		this.monDay = { date: this.monday, events: [] };
		this.tueDay = { date: this.tuesday, events: [] };
		this.wedDay = { date: this.wednesday, events: [] };
		this.thuDay = { date: this.thursday, events: [] };
		this.friDay = { date: this.friday, events: [] };
		this.satDay = { date: this.saturday, events: [] };
		let days = [
			this.sunDay,
			this.monDay,
			this.tueDay,
			this.wedDay,
			this.thuDay,
			this.friDay,
			this.satDay,
		];
		let today = days.find(function (el) {
			return sameDay(el.date, new Date());
		});
		return today === undefined ? { date: new Date(), events: [] } : today;
	}

	setupThisWeeksDays() {
		this.sunday = getSunday(addDays(new Date(), this.page * 7));
		this.monday = addDays(this.sunday, 1);
		this.tuesday = addDays(this.sunday, 2);
		this.wednesday = addDays(this.sunday, 3);
		this.thursday = addDays(this.sunday, 4);
		this.friday = addDays(this.sunday, 5);
		this.saturday = addDays(this.sunday, 6);
		this.setupMonthTitles();
	}

	setupMonthTitles() {
		this.monthLeft = "";
		this.monthRight = "";
		this.monthLeftColumns = 12;
		this.monthRightColumns = 0;
		let leftSideDays = 1;
		let rightSideDays = 0;
		let days = [
			this.monday,
			this.tuesday,
			this.wednesday,
			this.thursday,
			this.friday,
			this.saturday,
		];
		days.forEach((element) => {
			if (element.getMonth() == this.sunday.getMonth()) {
				leftSideDays++;
			} else {
				rightSideDays++;
			}
		});
		if (leftSideDays > 2) {
			this.monthLeft = this.month[this.sunday.getMonth()];
		} else {
			this.monthRightColumns = 12;
			this.monthLeftColumns = 0;
		}
		if (rightSideDays > 2) {
			this.monthRight = this.month[this.saturday.getMonth()];
		} else {
			this.monthLeftColumns = 12;
			this.monthRightColumns = 0;
		}

		if (leftSideDays > 2 && rightSideDays > 2) {
			this.monthLeftColumns = Math.ceil((leftSideDays / 7) * 12);
			this.monthRightColumns = 12 - this.monthLeftColumns;
		}
	}

	setCalendarDates(): void {
		this.sunDay = {
			date: this.sunday,
			events: this.getEventsForDate(this.sunday),
		};
		this.monDay = {
			date: this.monday,
			events: this.getEventsForDate(this.monday),
		};
		this.tueDay = {
			date: this.tuesday,
			events: this.getEventsForDate(this.tuesday),
		};
		this.wedDay = {
			date: this.wednesday,
			events: this.getEventsForDate(this.wednesday),
		};
		this.thuDay = {
			date: this.thursday,
			events: this.getEventsForDate(this.thursday),
		};
		this.friDay = {
			date: this.friday,
			events: this.getEventsForDate(this.friday),
		};
		this.satDay = {
			date: this.saturday,
			events: this.getEventsForDate(this.saturday),
		};
	}

	async mounted(): Promise<void> {
		this.requestEvents();
		this.setupThisWeeksDays();
		this.setCalendarDates();
	}

	async requestEvents(): Promise<void> {
		this.loading = true;
		await this.fetchEvents();
		this.loading = false;
		this.setCalendarDates();
	}

	@Watch("page")
	onPageChanged(): void {
		this.setupThisWeeksDays();
		this.setCalendarDates();
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	getEventsForDate(date: Date) {
		let events = this.$store.state.Events.events.filter((element: any) => {
			return sameDay(new Date(element.start.dateTime), date);
		});
		return events;
	}

	daySelected(day: CalendarDay): void {
		this.selectedDay = day;
	}
}
