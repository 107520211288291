






































































import "@/header/assets/hamburgers.css";
import { Component, Vue } from "vue-property-decorator";
import "@/assets/variables.css";

@Component
export default class Header extends Vue {
	open = false;
	scrolled = false;

	scrollToTop() {
		window.scrollTo(0, 0);
	}

	toggleMenu() {
		this.open = !this.open;
	}

	homeLogoClicked() {
		if (this.open) {
			this.toggleMenu();
		}
		this.scrollToTop();
	}

	mobileMenuItemSelected() {
		this.toggleMenu();
		this.scrollToTop();
	}

	handleScroll() {
		this.scrolled = window.scrollY > 0;
	}

	created() {
		window.addEventListener("scroll", this.handleScroll);
	}

	destroyed() {
		window.removeEventListener("scroll", this.handleScroll);
	}
}
