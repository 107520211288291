/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import CalendarEvent from "@/model/CalendarEvent";
import { listEvents } from "@/api/events.api";

const initialState = () => ({
	events: [],
});

// State object
const state = initialState();

// Getter functions
const getters = {
	getEvents(state) {
		return state.events;
	},
};

// Actions
const actions = {
	reset({ commit }) {
		commit("RESET");
	},
	async fetchEvents({ commit }) {
		try {
			const response = await listEvents();
			commit("SET_CALENDAR_EVENTS", response.data.items);
		} catch (error) {
			console.log(error);
			// handle the error here
		}
	},
};
// Mutations
const mutations = {
	RESET(state) {
		const newState = initialState();
		Object.keys(newState).forEach((key) => {
			state[key] = newState[key];
		});
	},
	SET_CALENDAR_EVENTS(state, data) {
		state.events = data;
	},
};
export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
