import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "@/home/ui/HomeNew.vue";
import About from "@/about/ui/OurStory.vue";
import Catering from "@/catering/ui/Catering.vue";
import Locations from "@/locations/ui/Locations.vue";
import FAQ from "@/faq/ui/FAQ.vue";
import WarmingInstructions from "@/warming/ui/WarmingInstructions.vue";
import Flavors from "@/flavors/ui/Flavors.vue";
import NotFound404 from "@/404/ui/404.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
	{
		path: "/",
		name: "Home",
		component: Home,
	},
	{
		path: "/catering",
		name: "Catering",
		component: Catering,
	},
	{
		path: "/about",
		name: "About",
		component: About,
	},
	{
		path: "/locations",
		name: "Locations",
		component: Locations,
	},
	{
		path: "/faq",
		name: "FAQ",
		component: FAQ,
	},
	{
		path: "/warming",
		name: "Warming Instructions",
		component: WarmingInstructions,
	},
	{
		path: "/flavors",
		name: "Flavors",
		component: Flavors,
	},
	{
		path: "*",
		name: "Not Found",
		component: NotFound404,
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

export default router;
