






































import { Component, Prop, Vue } from "vue-property-decorator";
import KnotFlavor from "@/model/KnotFlavor";

@Component
export default class FlavorDetails extends Vue {
	@Prop() private flavor!: KnotFlavor;
	@Prop() private imageRight!: boolean;
}
