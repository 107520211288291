












import { Component, Vue } from "vue-property-decorator";
import Schedule from "@/schedule/Schedule.vue";
import PageHeaderImageTitleDescription from "@/components/PageHeaderImageTitleDescription.vue";

@Component({
	components: {
		Schedule,
		PageHeaderImageTitleDescription,
	},
})
export default class Locations extends Vue {}
