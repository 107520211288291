































































import { Component, Vue } from "vue-property-decorator";
import FlavorDetails from "@/components/FlavorDetails.vue";
import { isOdd } from "@/helpers/helpers";
import PageHeaderImageTitleDescription from "@/components/PageHeaderImageTitleDescription.vue";

@Component({
	components: {
		FlavorDetails,
		PageHeaderImageTitleDescription,
	},
	methods: {
		isOdd,
	},
})
export default class Flavors extends Vue {
	flavors = [];
}
