<template>
	<div id="app">
		<Header />
		<router-view />
		<Footer />
	</div>
</template>

<script>
import Header from "@/header/ui/Header.vue";
import Footer from "@/footer/ui/Footer.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
	components: {
		Header,
		Footer,
	},
})
export default class App extends Vue {}
</script>

<style lang="scss">
#app {
	font-family: "Montserrat", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #000000;
}

#nav {
	padding: 30px;

	a {
		font-weight: bold;
		color: #000000;

		&.router-link-exact-active {
			color: #000000;
		}
	}
}

@font-face {
	font-family: "Veneer";
	src: local("Veneer"), url("~@/assets/veneer_two.otf") format("opentype");
}

@font-face {
	font-family: "VeneerThree";
	src: local("VeneerThree"),
		url("~@/assets/VeneerThree.ttf") format("truetype");
}

@font-face {
	font-family: VeneerTwo;
	src: url("~@/assets/VeneerTwo.woff2") format("woff2"),
		url("~@/assets/VeneerTwo.woff2") format("woff");
}

@font-face {
	font-family: "Smithen";
	src: local("Smithen"), url("~@/assets/Smithen.otf") format("opentype");
}

@font-face {
	font-family: "MoonTime";
	src: local("MoonTime"), url("~@/assets/MoonTime.ttf") format("truetype");
}
</style>
