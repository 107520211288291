import { JWT } from "google-auth-library";
import keys from "@/assets/jwt-keys.json";

const client = new JWT({
	email: keys.client_email,
	key: keys.private_key,
	scopes: [
		"https://www.googleapis.com/auth/calendar",
		"https://www.googleapis.com/auth/admin.directory.resource.calendar",
		"https://www.googleapis.com/auth/calendar.events.readonly",
		"https://www.googleapis.com/auth/calendar.readonly",
	],
});

export default client;
